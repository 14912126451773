<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
          :plan-options="planOptions" @refetch-data="refetchData" />
    
        <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
          :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <!-- <b-row no-body class="mt-2"> -->
    <b-card no-body class="mb-2">
      <div class="m-2">
        <b-row class="d-flex justify-content-between w-100">
          <!-- <div class="w-100 d-flex justify-content-end mb-2"> -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <b-col md="6" class="d-flex mt-1">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px"
                  placeholder="Search..."
                  :options="getOptions('customers')"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <!-- <div>
                <v-select
                  style="width: 200px;"
                  placeholder="Search..."
                  :options="searchTerm.name === 'Category' ? getOptions('category') : staticUsers"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div> -->

              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  :options="searchOptions"
                  @input="changeSearch()"
                />
              </div>
            </div>
          </b-col>
          <!-- </div> -->
        </b-row>
      </div>
      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="staticUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <!-- <template #thead-top="{ fields }">
          <tr>
            <th v-for="(field, index) in fields" :key="index">
              <template v-if="field.key == 'id' || field.key == 'Approval' || field.key == 'Reject'">
                &nbsp;
              </template>
              <template v-else>
                <b-form-input
                  v-model="columnFilters[field.key]"
                  @input="filterTable()"
                  placeholder="search..."
                />
              </template>
            </th>
          </tr>
        </template> -->

        <template #cell(f_name)="data">
          <div class="d-flex w-100 align-items-center">
            <div v-if="data.item.user">
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.user.image"
                  :text="data.item.user.f_name.substring(0, 1)"
                />
              </span>
              <span class="font-weight-bolder title">
                {{ data.item.user ? data.item.user.f_name : "" }} -
                {{
                  data.item.user && data.item.user.type == "customer"
                    ? "Client"
                    : "Trainer"
                }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(title)="data">
          <div
            style="cursor: pointer"
            class="d-flex w-100"
            @click="openVideoModal(data.item)"
          >
            <!-- <video v-else class="text-dark w-100 ImageLink" ref="videoPlayer" controls>
                            <source :src="data.item.url" type="video/mp4">
                          </video> -->

            <template v-if="data.item && data.item.thumbnail">
              <img :src="data.item.thumbnail" width="50" height="50" />
            </template>
            <template v-else>
              <span>
                <img
                  style="height: 40px; width: 40px"
                  src="../../../../../../public/no-image.png"
                  alt=""
                />
              </span>
            </template>
            <span class="font-weight-bold ml-1 title">{{
              data.item.title
            }}</span>
          </div>
        </template>
        <template #cell(Approval)="staticUsers">
          <div
            class="d-flex align-items-center form-control cursor-pointer w-75"
            @click="ApproveVideo('Approved', staticUsers.item.id)"
          >
            <b-img
              class="cardImageInner"
              style="margin: 7px"
              fluid
              :src="right"
              alt="Log"
            />
            <span>
              <p class="m-0 font-weight-bolder title text-dark">
                Approve Video
              </p>
            </span>
          </div>
        </template>
        <template #cell(Reject)="staticUsers">
          <div
            class="d-flex align-items-center form-control cursor-pointer w-75"
            @click="openModal(staticUsers.item.id)"
          >
            <b-img class="cardImageInner mr-1" fluid :src="close" alt="Log" />
            <span>
              <p class="m-0 font-weight-bolder text-dark title">Reject Video</p>
            </span>
          </div>
        </template>
        <!-- <template #cell(Action)='data'>
                    <feather-icon icon="MailIcon" size="22" class="m-1 cursor-pointer"  />
                    <feather-icon icon="EyeIcon" size="22" class="m-1" @click="openProfile(data.item)"
                       pe-auto   />
                </template> -->
      </b-table>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <b-modal
      v-model="videoModal"
      :title="selectedVideo ? selectedVideo.title : ''"
      class="text-primary"
      hide-footer
    >
      <video
        :src="selectedVideo ? selectedVideo.url : ''"
        controls
        autoplay
        class="w-100"
      ></video>
    </b-modal>

  <b-modal
    v-model="reject_popup"
    no-close-on-backdrop
    hide-footer
    centered
    size="md"
  >
    <validation-observer ref="simpleRules">
      <b-form ref="myFormMain">
        <b-row class="p-1">
          <b-col md="12" class="my-2 text-center">
            <!-- {{ CustomerData }} -->
            <h3
              class="font-weight-bolder"
              style="font-size:26px; font-family:Public Sans, sans's serif"
            >
              Reject Reason
            </h3>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="Reason">
                <b-form-input
                  type="text"
                  v-model="reason"
                  maxlength="100"
                  placeholder="Write a reason"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- @click="Congo" -->
          <b-col md="12" class="d-flex justify-content-center mt-2">
            <b-button
              type="submit"
              @click.prevent="ApproveVideo('Rejected',reject_videoid)"
              :disabled='loading'
              variant="primary"
              class="mr-1"
              style="font-family: Public Sans"
            >
              Submit
            </b-button>
            <b-button
              type="reset"
              variant="primary"
              @click="closemodel()"
              style="font-family: Public Sans"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormFile,
  BImg,
  BSpinner,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import right from "../../../../../assets/images/svg/right.svg";
// import right from "../../../assets/images/svg/right.svg";
import close from "../../../../../assets/images/svg/close.svg";
// import close from "../../../assets/images/svg/close.svg";
import axios from "axios";
import _ from "lodash";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BSpinner,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    vSelect,
    BFormFile,
    ValidationProvider, ValidationObserver,
    BForm
  },
  data() {
    return {
      right,
      isLoading: false,
      close,
      tableColumns: [
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        { key: "title", label: "Videos" },
        { key: "f_name", label: "User" },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        { key: "Approval", label: "Approval" },
        { key: "Reject", label: "Reject" },
        // { key: 'balance', sortable: true },
      ],
      sortBy: "",
      loading:false,
      orderBy: true ? "ASC" : "DESC",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      isSortDirDesc: true,
      staticUsers: [],
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      params: {},
      columnFilters: {},
      per_page: 10,
      search: "",
      videoModal: false,
      selectedVideo: null,
      searchTerm: {
        name: "Client",
        value: "client",
        type: "dropdown",
        displayName: "f_name",
      },
      searchOptions: [
        {
          name: "Client",
          value: "client",
          type: "dropdown",
          displayName: "f_name",
        },
      ],
      reject_videoid: 0,
      reason:'',
      reject_popup:false
    };
  },
  computed: {
    processedUsers() {
      return this.staticUsers.map((user) => {
        const avatars = user.avatars || [];
        const topThreeAvatars = avatars.slice(0, 3);
        const remainingCount = Math.max(avatars.length - 3, 0);
        return { ...user, topThreeAvatars, remainingCount };
      });
    },
  },
  mounted() {
    this.getPendingVideo();
  },
  methods: {
    closemodel(){
      this.reject_videoid=0;
      this.reject_popup = false
    },
    openModal(id) {
      this.reject_videoid = id;
      this.reject_popup = true;
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "ASC" : "DESC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getPendingVideo();
    },
    openVideoModal(item) {
      this.selectedVideo = item;
      this.videoModal = true;
    },
    handleSearch: _.debounce(function () {
      this.getPendingVideo();
    }, 1000),
    async getPendingVideo() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      } else if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}video/get-pending`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((response) => {
          this.staticUsers = response.data.data.data;
          // console.log(this.staticUsers)
          this.paginationTotal = response.data.data.total;
          this.paginationStart = response.data.data.from;
          this.paginationEnd = response.data.data.to;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    ApproveVideo(e, id) {
      const customerData = {
        approve: e,
        id: id,
        rejectreason:this.reason
      };
      let confirmation;
      if (e === "Approved") {
        confirmation = "Yes, Approve it!";
      } else {
        confirmation = "Yes, Reject it!";
      }

      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmation,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading=true
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}video/approval-request`,
            data: JSON.stringify(customerData),
          };

          axios(requestOptions)
            .then((response) => {
              this.$swal({
                title: "Submitted",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Video Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.reject_popup =false;
              this.reject_videoid =0;
              this.reason ='';
              this.getPendingVideo();
              this.$forceUpdate();
            })
            .catch((error) => {
              if (error.response.data.message) {
                this.$swal({
                  title: "Error!",
                  text: error.response.data.message,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal({
                  title: "Error!",
                  text: `${error}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
            .finally(()=>{
              this.loading=false

            })
        }
      });
    },
    changePagination() {
      this.getPendingVideo();
    },
    // removeRow(index) {
    //     this.staticUsers.splice(index, 1);
    // },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({ path: `/trainermanagement/${userId}` });
    },
    openTrainerProfile(item) {
      const userId = item.Trainer;
      this.$router.push({ path: `/trainerprofile/${userId}` });
    },
  },
};
</script>
<style>
.avatar-container {
  display: flex;
  align-items: center;
}

.remaining-count {
  margin-left: 5px;
  font-weight: bold;
}

.title {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}
</style>